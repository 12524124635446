import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker';
import './index.scss';

const App = () => (
    <React.Fragment>
        <section className="content main-page">
            <div className="main-page__title">
                <span className="main-page__desc"><span className="main-page__highlight">Web Developer</span></span>
                <span className="main-page__name main-page__name--first">Parththipan</span>
                <span className="main-page__name main-page__name--last">Thaniperumkarunai</span>
                <span className="main-page__text">Like to learn new things.</span>
            </div>
        </section>
        <div className="social-media">
            <ul className="social-media__container">
                <li className="social-media__item">
                    <a href="https://twitter.com/The_Parthy" target="_blank" rel="noopener noreferrer"
                       className="social-media__link fa fa-twitter">Twitter</a>
                </li>
                <li className="social-media__item">
                    <a href="https://github.com/theparthy" target="_blank" rel="noopener noreferrer"
                       className="social-media__link fa fa-github">Github</a>
                </li>
            </ul>
        </div>
    </React.Fragment>
);

ReactDOM.render(<App/>, document.getElementById('root'));
serviceWorker.register();
